.chrono-timeline .readreceipt {
	float: right;
    margin-left: 13px;
    margin-right: 19px;
}
.chrono-timeline .readreceipt i {
	color: #a0a0a0;
    font-size: 20px;
    margin-top: -2px;
    margin-right: 16px;
    cursor: pointer;
    opacity:0.7;
}
.chrono-timeline .readreceipt i:hover {
	opacity:1.0;
}

.chrono-timeline .readreceipt .active i {
	color: #296fef;
	opacity:1.0;
}

.chrono-timeline .sl-ent {
	display: flex;
	min-height: 40px;
}
.chrono-timeline .sl-ent > * {
	flex:1;
}
.chrono-timeline .sl-ent > .sl-line {
	max-width:30px;
	border-left:1px solid transparent;
	margin-left: 15px;
	position:relative;
}
.chrono-timeline .sl-ent > .sl-line.solid {
	border-left:1px solid #00000030;
}
.chrono-timeline .sl-ent > .sl-line.dotted {
	background-image: linear-gradient(#00000030 60%, rgba(255,255,255,0) 0%);
    background-position: left;
    background-size: 1px 9px;
    background-repeat: repeat-y;
    padding-left: 1px;
    border: none;
}
.chrono-timeline .sl-ent > .sl-line .img {
	position:absolute;
	border:1px solid red;
	width: 31px;
    height: 31px;
    border-radius: 16px;
    background: white;
    border: 1px solid #e6e6e6;
    margin-left: -17px;
}
.chrono-timeline .sl-ent > .sl-line .img svg {
	width: 58%;
    height: 58%;
    margin-top: 22%;
    margin-left: 22%;
    fill: #999;
}

.chrono-timeline .sl-ent-grp {
	margin: 10px;
    margin-top: 15px;
    text-align: center;
}
.chrono-timeline .sl-ent-grp h3 {
	font-size: 15px;
    font-style: italic;
    color: #bbb;
    font-weight: normal;
	margin: 10px 0 5px;
}
.chrono-timeline .sl-ent-grp ul {
	display: inline-block;
    color: #777;
	margin: 5px 10px 10px;
	padding: 0;
}
.chrono-timeline .sl-ent-grp ul li {
    float: left;
    font-size: 13px;
    list-style: none;
    border-bottom: 1px solid transparent;
}
.chrono-timeline .sl-ent-grp ul li span {
    color: #bbb;
    margin-left: 4px;
}
.chrono-timeline .sl-ent-grp ul li.active {
    color: #f19f08;
}
.chrono-timeline .sl-ent-grp ul li:hover {
    border-bottom-color: #989898;
    cursor:pointer;
}
.chrono-timeline .sl-ent-grp ul li a {
    text-decoration:none;
    padding: 5px 10px;
    display: inline-block;
}

.chrono-timeline .ent-todo {
    padding: 10px;
}
.chrono-timeline .ent-todo h3 {
    padding: 0px;
    margin: 7px 0px;
}
.chrono-timeline .sl-ent .bubble {
	border: 1px solid black;
	margin-bottom: 10px;
	border-radius: 2px;
	min-height: 31px;
}
.chrono-timeline .sl-ent .bubble > svg.arrow {
	transform: rotate(-90deg);
    width: 20px;
    height: 20px;
    margin-left: -14px;
    margin-top: 6px;
    position: absolute;
}
.chrono-timeline .sl-ent .bubble .content-wrap {
    padding: 0px 18px;
    position:relative;
}
.chrono-timeline .sl-ent .bubble .content-wrap .header .name {
	display: inline-block;
    font-size: 12px;
    color: #777;
}
.chrono-timeline .sl-ent .bubble .content-wrap .header .date {
    display: inline-block;
    font-size: 12px;
    margin-right: 15px;
	color: #777;
}
.chrono-timeline .sl-ent .bubble .content-wrap .header .date .datetime {
	cursor:pointer;
}
.chrono-timeline .sl-ent .bubble .content-wrap .header .date .datetime input {
	font-size: 12px;
    width: 100px;
    height: 14px;
    color: #777;
    text-align: center;
}
.chrono-timeline .sl-ent .bubble .content-wrap .header .date .datetime input:focus {
  
}

.chrono-timeline .sl-ent .bubble .content-wrap .header .date .Today {
    color: #ef9b1e;
}
.chrono-timeline .sl-ent .bubble .content-wrap .header .date .Urgent {
    color: #e00000;
}

.chrono-timeline .sl-ent .bubble .content-wrap .header .new-entry-name {
	color:limegreen; font-weight:bold;
}


.chrono-timeline .sl-ent .bubble .content-wrap .changes {
    display:table;
    margin-top:8px;
    margin-bottom:-2px;
}
.chrono-timeline .sl-ent .bubble .content-wrap .changes .change  {
    display:table-row;
}
.chrono-timeline .sl-ent .bubble .content-wrap .changes .change .name {
    padding-right: 5px;
    padding-left: 0;
    color: #888;
    min-width: 80px;
}
.chrono-timeline .sl-ent .bubble .content-wrap .changes .change > * {
    display: table-cell;
    padding: 3px 0;
}
.chrono-timeline .sl-ent .bubble .content-wrap .changes .change .ticket-color {
    padding: 2px 3px;
    border-radius:3px;
}

.chrono-timeline .sl-ent .bubble .content-wrap .content {
    padding-top:4px;
}

.chrono-timeline .sl-ent .files {
    margin-top:7px;
}
.chrono-timeline .sl-ent .files .file {
	padding:3px 0;
}
.chrono-timeline .sl-ent .files .file > span {
	display: inline-block;
    line-height: 20px;
    margin-right: 8px;
}
.chrono-timeline .sl-ent .files .file > span.type {
	width:17px;
	position: relative;
	vertical-align: top;
}
.chrono-timeline .sl-ent .files .file span.type svg {
	fill: #a9a9a9;
	width: 15px;
    height: 23px;
    position: absolute;
    top: 0px;
}
.chrono-timeline .sl-ent .files .file a.name {
	margin-right: 10px;
	padding: 1px 0;
	text-decoration:none;
	color:inherit;
}
.chrono-timeline .sl-ent .files .file a.name:hover {
	text-decoration:underline;
}
.chrono-timeline .sl-ent .files .file span.size {
	color:#999;
}


.chrono-timeline .sl-ent .checklist .item .activity {
    margin-left: 19px;
    background: #b7b7b7;
    padding: 3px 4px;
    border-radius: 4px;
    color: white;
    text-decoration: none;
    font-size: 11px;
    position: relative;
    top: -1px;
    float: right;
}
.chrono-timeline .sl-ent .checklist .item .activity:hover {
	background:orange;
}
.chrono-timeline .sl-ent .checklist .item .desc {
    margin-left: 25px;
    font-style: italic;
    margin-top: 4px;
    margin-bottom: 2px;
    white-space: pre;
}


input[type=checkbox].cb-svg {
  display: none;
}
input[type=checkbox].cb-svg + label svg {
  height: 200px;
}
input[type=checkbox].cb-svg + label svg .check {
  transition: stroke-dashoffset 0.5s ease-in-out 0.2s, stroke-width 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
input[type=checkbox].cb-svg + label svg .outer {
  transition: fill .1s ease-out;
  fill: #BDBDBD;
}
input[type=checkbox].cb-svg + label svg .inner {
  fill: #FFFFFF;
  transition: opacity .1s ease-in-out .1s, height .1s ease-in-out .1s, width .1s ease-in-out .1s, x .1s ease-in-out .1s, y .1s ease-in-out .1s, rx .1s ease-in-out .1s;
  opacity: 1;
  height: 56px;
  width: 56px;
  x: 4px;
  y: 4px;
  rx: 4px;
}
input[type=checkbox].cb-svg + label:hover svg .check {
  stroke-dashoffset: 0;
  stroke-width: 8px;
}
input[type=checkbox].cb-svg + label:hover svg .outer {
  fill: #9E9E9E;
}
input[type=checkbox].cb-svg + label:hover svg .inner {
  opacity: 0;
  height: 0px;
  width: 0px;
  x: 12px;
  y: 28px;
  rx: 100px;
}
input[type=checkbox].cb-svg:checked + label svg .check {
  stroke-dashoffset: 0;
  stroke-width: 7px;
}
input[type=checkbox].cb-svg:checked + label svg .outer {
  transition: fill 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  fill: #2196F3;
}
input[type=checkbox].cb-svg:checked + label:hover svg .outer {
  fill: #50b1ff;
}
input[type=checkbox].cb-svg:checked + label svg .inner {
  height: 0px;
  width: 0px;
  x: 16px;
  y: 16px;
  rx: 100px;
}


.chrono-timeline .sl-ent .checklist {
	margin-top: 6px;
    margin-bottom: -3px;
}
.chrono-timeline .sl-ent .checklist input[type=checkbox].cb-svg + label svg {
	height: 19px;
    position: absolute;
    top: 0px;
    margin-right: 5px;
	left: -1px;
}
.chrono-timeline .sl-ent .checklist .item label {
    display: inline-block;
    position: relative;
	padding: 3px 0;
    padding-left: 25px;
}
.chrono-timeline .sl-ent .checklist .item {
    padding: 3px 0;
    display:block;
}



input[type=checkbox].td-svg {
  display: none;
}
input[type=checkbox].td-svg + label svg {
  height: 200px;
}
input[type=checkbox].td-svg + label svg .check {
  transition: stroke-dashoffset 0.5s ease-in-out 0.2s, stroke-width 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
input[type=checkbox].td-svg + label svg .outer {
  transition: fill .1s ease-out;
  fill: #BDBDBD;
}
input[type=checkbox].td-svg + label svg .inner {
  fill: #FFFFFF;
  transition: opacity .1s ease-in-out .1s, height .1s ease-in-out .1s, width .1s ease-in-out .1s, x .1s ease-in-out .1s, y .1s ease-in-out .1s, rx .1s ease-in-out .1s;
  opacity: 1;
  height: 56px;
  width: 56px;
  x: 4px;
  y: 4px;
  rx: 4px;
}
input[type=checkbox].tb-svg + label:hover svg .check {
  stroke-dashoffset: 0;
  stroke-width: 8px;
}
input[type=checkbox].td-svg + label:hover svg .outer {
  fill: #9E9E9E;
}
input[type=checkbox].td-svg + label:hover svg .inner {
  opacity: 0;
  height: 0px;
  width: 0px;
  x: 12px;
  y: 28px;
  rx: 100px;
}
input[type=checkbox].td-svg:checked + label svg .check {
  stroke-dashoffset: 0;
  stroke-width: 7px;
}
input[type=checkbox].td-svg:checked + label svg .outer {
  transition: fill 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  fill: #2196F3;
}
input[type=checkbox].td-svg:checked + label:hover svg .outer {
  fill: #50b1ff;
}
input[type=checkbox].td-svg:checked + label svg .inner {
  height: 0px;
  width: 0px;
  x: 16px;
  y: 16px;
  rx: 100px;
}


.chrono-timeline .sl-ent .todo {
	
}
.chrono-timeline .sl-ent .todo input[type=checkbox].td-svg + label svg {
    position: absolute;
    top: -1px;
    left: -2px;
    height: 21px;
    width: 21px;
    fill: #999;
}
.chrono-timeline .sl-ent .todo input[type=checkbox].td-svg + label:hover svg {
    fill: #bbb;
}
.chrono-timeline .sl-ent .todo input[type=checkbox].td-svg:checked + label svg {
    fill: #83da1b;
}
.chrono-timeline .sl-ent .todo input[type=checkbox].td-svg:checked + label:hover svg {
    fill: #8ee625;
}
.chrono-timeline .sl-ent .todo label {
    display: inline-block;
    position: relative;
	padding: 3px 0;
    padding-left: 26px;
}
.chrono-timeline .sl-ent .todo a {
    text-decoration:none;
}
.chrono-timeline .sl-ent .todo a:hover {
    text-decoration:underline;
    color:black;
}
.chrono-timeline .sl-ent .todo {
    padding: 3px 0;
    padding-top: 10px;
}

.chrono-timeline .sl-ent .todo .bar {
    margin-top: 8px;
}
.chrono-timeline .sl-ent .todo .todo-wrap {
    margin: 10px 0;
}
.chrono-timeline .sl-ent .todo .handle {
    margin-left:10px;
}


.px-todo-due span.Normal { color:#067ad0 }
.px-todo-due span.Urgent { color:#e65f5c; }
.px-todo-due span.Today { color:#f1b04e; }
.px-todo-due span.gray { color:#999; }



/*
	tinyMCE
*/
.chrono-timeline .sl-ent .tinymce-bright .mce-panel {
	background-color: transparent;
    border: none;
}
.chrono-timeline .sl-ent .tinymce-bright .mce-toolbar-grp {
    padding: 4px 0;
}
.chrono-timeline .sl-ent .tinymce-bright .mce-statusbar,
.chrono-timeline .sl-ent .tinymce-bright .mce-menubar,
.chrono-timeline .sl-ent .tinymce-bright .mce-toolbar-grp {
    opacity: 0.3;
}
.chrono-timeline .sl-ent .tinymce-bright .mce-statusbar:hover,
.chrono-timeline .sl-ent .tinymce-bright .mce-menubar:hover,
.chrono-timeline .sl-ent .tinymce-bright .mce-toolbar-grp:hover {
    opacity: 1;
}
.chrono-timeline .sl-ent .tinymce-bright .mce-btn {
	background-color: transparent;
}
.chrono-timeline .sl-ent .tinymce-bright .mce-btn .mce-txt {
	font-size:12px;
}
.chrono-timeline .sl-ent .tinymce-bright .mce-path {
	padding:4px;
}
.chrono-timeline .sl-ent .tinymce-bright .mce-ico {
	text-align:center;
	font-size: 16px !important;
}
.chrono-timeline .sl-ent .tinymce-bright .mce-container, 
.chrono-timeline .sl-ent .tinymce-bright .mce-container *, 
.chrono-timeline .sl-ent .tinymce-bright .mce-widget, 
.chrono-timeline .sl-ent .tinymce-bright .mce-widget *, 
.chrono-timeline .sl-ent .tinymce-bright .mce-reset {
	font-size:12px;
}
.chrono-timeline .sl-ent .tinymce-bright .mce-edit-area iframe {
	border:1px solid #ccc;
	border-width:1px 0;
}

.chrono-timeline .sl-ent .mail .header,
.chrono-timeline .sl-ent .comment .header {
	float: left;
	padding-top: 8px;
}
.chrono-timeline .sl-ent .px-cfg-buttons.actions {
	float: right;
}
.chrono-timeline .sl-ent .px-cfg-buttons.actions a {
	display:inline-block;
	text-decoration:none;
	font-size:12px;
    opacity: 0.5;
    padding: 4px 6px;
    margin-top: -4px;
}
.chrono-timeline .sl-ent .px-cfg-buttons.actions a:first-child {
	margin-left: 0;
}
.chrono-timeline .sl-ent .px-cfg-buttons.actions a:last-child {
	margin-right: 0;
}
.chrono-timeline .sl-ent .px-cfg-buttons.actions a.button.pin {
    padding: 3px;
    background: none;
    margin-right: -12px;
}
.chrono-timeline .sl-ent .px-cfg-buttons.actions a.button.pin > svg {
    width: 15px;
    height: 15px;
    fill: #757575;
    padding: 0;
    margin-top: -2px;
    top: 3px;
    position: relative;;
}
.chrono-timeline .sl-ent .px-cfg-buttons.actions a.button.pin.selected > svg {
    fill: #e68412;
}
    

.chrono-timeline .sl-ent .px-cfg-buttons.actions a:hover {
    color: inherit;
    opacity: 1;
}

.chrono-timeline .sl-ent .comment .content {
	clear:both;
}
.chrono-timeline .sl-ent .comment.readonly .content > *:first-child {
    margin-top: 3px;
}
.chrono-timeline .sl-ent .comment.readonly .content > *:last-child {
    margin-bottom: 11px;
}
.chrono-timeline .sl-ent .comment .last-edit {
    margin: 10px 0;
    font-size: 11px;
    color: #777;
}
.chrono-timeline .sl-ent .comment .px-cfg-buttons.types {
    clear:both;
    padding:4px 0;
}
.chrono-timeline .sl-ent .comment .mce-tinymce {
	margin-top:-6px;
}
.chrono-timeline .sl-ent .comment .px-cfg-buttons.types a {
    display: inline-block;
    text-decoration: none;
    background: #a0a0a0;
    padding: 2px 6px;
    border-radius: 3px;
    color: white;
    margin-right: 10px;
    font-size: 13px;
}
.chrono-timeline .sl-ent .comment .px-cfg-buttons.types a.selected {
    background: orange;
    opacity: 1;
}
.chrono-timeline .sl-ent .comment .px-cfg-buttons.types a.hide {
    display:none;
}


/* leider gibt es ein .button in old.css, das sind die settings die ich zurüksetzen muss */
.chrono-timeline .sl-ent .px-cfg-buttons .button {
    margin: 0;
    border:none;
}
.chrono-timeline .sl-ent .px-cfg-buttons a.button {
    display: inline-block;
    text-decoration: none;
    background: #a0a0a0;
    padding: 2px 6px;
    border-radius: 3px;
    color: white;
    margin-right: 10px;
    font-size: 12px;
    opacity: 0.7;
}
.chrono-timeline .sl-ent .px-cfg-buttons a.button.disabled {
    opacity: 0.4;
}
.chrono-timeline .sl-ent .px-cfg-buttons a:hover.button {
    opacity: 1;
    color: white;
}
.chrono-timeline .sl-ent .px-cfg-buttons a:hover.button.disabled {
    opacity: 0.4;
    color: white;
    cursor:default;
}
.chrono-timeline .sl-ent .px-cfg-buttons a.button.selected {
    background: orange;
    opacity: 1;
}



/*
	mail 
*/
.chrono-timeline .sl-ent .mail .fields {}
.chrono-timeline .sl-ent .mail .fields .field input {border:none;outline:none;line-height: 2.7em;padding: 0; width:100%; }
.chrono-timeline .sl-ent .mail .fields .field select { border: none; outline: none; line-height: 2.7em; padding: 9px 0; margin-left: -3px; }
.chrono-timeline .sl-ent .mail .fields .field { border-bottom:1px solid #ccc; line-height: 2.7em; }
.chrono-timeline .sl-ent .mail .fields .field > :first-child { color:#777; max-width:66px; }
.chrono-timeline .sl-ent .mail .fields .field > .id { max-width:200px; text-align: right; white-space: nowrap;}

.chrono-timeline .sl-ent .mail .fields .field { display:flex; }
.chrono-timeline .sl-ent .mail .fields .field > * {flex: 1 0 auto;}

.chrono-timeline .sl-ent .mail .fields .field.to .angle {
    padding: 0px 10px;
    margin-left: 7px;
    font-size: 24px;
    position: absolute;
    color: #ccc;
    cursor: pointer;
}
.chrono-timeline .sl-ent .mail .fields .field.to .angle:hover {
    color: #666;
}

.chrono-timeline .sl-ent .mail .fields .field.proposals .prop {
    margin-right: 15px;
    white-space: nowrap
}
.chrono-timeline .sl-ent .mail .fields .field.proposals .prop a {
    background: #9E9E9E;
    color: white;
    border-radius: 2px;
    padding: 2px 3px;
    margin-left: 2px;
    text-decoration: none;
}
.chrono-timeline .sl-ent .mail .fields .field.proposals .prop a:first-child {
    margin-left: 5px;
}
.chrono-timeline .sl-ent .mail .fields .field.proposals .prop a.active {
    background: #FF9800;
}

.chrono-timeline .sl-ent .mail .readonly .attachment {
    overflow: hidden;
    margin: 10px 0px;
    display: flex;
}
.chrono-timeline .sl-ent .mail .readonly .attachment .file {
    position: relative;
    z-index: 100;
    padding-right: 0px;
}
.chrono-timeline .sl-ent .mail .readonly .attachments .name {
    width: 154px;
}

.chrono-timeline .sl-ent .mail .fields .field.hide {
    display:none;
}

.chrono-timeline .sl-ent .mail .sendcommand {
    float: right;
    margin: 0px;
    z-index: 3;
	position: absolute;
	right: 8px;
	top: 8px;
}
.chrono-timeline .sl-ent .mail .sendcommand .info.red {
    color: #f44336;
    font-weight: bold;
    padding-right:15px;
}
.chrono-timeline .sl-ent .mail .sendcommand .btn {
    text-decoration: none;
    padding: 3px 7px;
    border-radius: 4px;
    font-size: 12px;
	margin-left: 0;
	padding-right: 7px;
}
.chrono-timeline .sl-ent .mail .sendcommand .btn.active {
    z-index: 1;
    position: relative;
    padding-right: 8px;
    padding-left: 8px;
}
.chrono-timeline .sl-ent .mail .sendcommand .green {
    background: #bbb;
}
.chrono-timeline .sl-ent .mail .sendcommand .green.active {
    background: #47b900;
}
.chrono-timeline .sl-ent .mail .sendcommand .red.active {
    background: #ce4c45;
    padding-left: 8px;
    padding-right: 8px;
}
.chrono-timeline .sl-ent .mail .sendcommand a.green:hover {
    background: #559e00;
}
.chrono-timeline .sl-ent .mail .sendcommand a.red:hover {
    background: #ce4c45;
}
.chrono-timeline .sl-ent .mail .sendcommand a.btn {
	padding-right: 8px;
	padding-left: 8px;
}
.chrono-timeline .sl-ent .mail .sendcommand a.btn.active {
    padding-right: 7px;
    padding-left: 12px;
}
.chrono-timeline .sl-ent .mail .sendcommand span.btn.active {
    padding-left: 8px;
    padding-right: 8px;
}

.chrono-timeline .sl-ent .mail .attachment input {
    width: 100%;
}
.chrono-timeline .sl-ent .mail .attachment .files-wrap {
    display:flex;
}
.chrono-timeline .sl-ent .mail .attachment .input-wrap label {
    background: #6f6f6f;
    width: 117px;
    height: 24px;
    display: inline-block;
    margin: 0px;
    margin-top: 0px;
    border-radius: 3px;
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    color: #fff;
    white-space: nowrap;
    vertical-align: middle;
    margin-top: -3px;
    padding-right: 7px;
    position:relative;
}

.chrono-timeline .sl-ent .mail .attachment .input-wrap label.dragover,
.chrono-timeline .sl-ent .mail .attachment .input-wrap label:hover {
    background: #e48800;
}
.chrono-timeline .sl-ent .mail .attachment .input-wrap label input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    width: 100%;
}
.chrono-timeline .sl-ent .mail .attachment .input-wrap label svg {
    width: 14px;
    float: left;
    height: 23px;
    margin-left: 8px;
}


.chrono-timeline .sl-ent .mail .attachments {
	overflow: hidden;
	flex-grow: 1;
	width: 1px;
	line-height: 2.2em;
	padding: 3px 0;
}
.chrono-timeline .sl-ent .mail .attachments > * {
    wwhite-space: nowrap;
}
.chrono-timeline .sl-ent .mail .attachments .file {
    line-height: 8px;
    font-size: 11px;
    background: #e4e4e4;
    padding: 6px 2px;
    border-radius: 4px;
    display: inline-block;
    top: -1px;
    position: relative;
    padding-right: 24px;
    margin-right: 7px;
    text-decoration: none;
}
.chrono-timeline .sl-ent .mail .attachments .ext {
    background: #7d7d7d;
    border-radius: 3px;
    color: white;
    padding: 0 6px;
    margin-right: 4px;
    position: relative;
    display: inline-block;
    margin-bottom: -10px;
    top: -5px;
    margin-left: -1px;
    height: 22px;
    line-height: 22px;
    vertical-align: middle;
    width: 17px;
    text-align: center;
}
.chrono-timeline .sl-ent .mail .attachments .name {
    width: 125px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1em;
    vertical-align: middle;
}
.chrono-timeline .sl-ent .mail .attachments .size {
    margin-right: 5px;
    margin-left: 3px;
    vertical-align: middle;
    width: 50px;
    display: inline-block;
    text-align: right;
}
.chrono-timeline .sl-ent .mail .attachments .del {
    background: #e2b1b1;
    width: 18px;
    border-radius: 4px;
    color: #fff7f7;
    height: 18px;
    display: inline-block;
    top: -5px;
    margin-right: 10px;
    position: relative;
    margin-bottom: -9px;
    text-align: center;
    vertical-align: middle;
    line-height: 18px;
    font-size: 11px;
    cursor: pointer;
    margin-left: -28px;
    padding: 2px;
    box-sizing: content-box;
}
.chrono-timeline .sl-ent .mail .attachments a.del:hover {
    background: #ff0000;
}

.chrono-timeline .sl-ent .mail iframe.correct {
    /* correct iframe inner body margin*/
    margin-left: -8px;
    width: calc(100% + 8px);
}
.chrono-timeline .sl-ent .mail iframe {
    border: none;
    display: block;
    width: 100%;
}
.chrono-timeline .sl-ent .mail h3 {
    padding: 0;
    margin: 0;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 7px;
    clear: both;
}
.chrono-timeline .sl-ent .mail .info {
    font-size: 12px;
    color: #777;
}
.chrono-timeline .sl-ent .mail .info span.prefix {
    padding-left: 8px;
    padding-right: 2px;
}

.chrono-timeline .sl-ent .mail .attachment span.size {
    width: 60px;
}

.chrono-timeline .sl-ent .mail .attachment span.ext {
    width: 30px;
}

/*
.chrono-timeline .sl-ent .mail iframe {
    border: none;
    display: block;
    padding-top: 4px;
    width: 100%;
    margin-left: -8px;
    width: calc(100% + 16px);
}
.chrono-timeline .sl-ent .mail h3 {
    padding: 0;
    margin: 0;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 7px;
    clear: both;
}
*/





.px-cfg-buttons .grp {
    display: inline-block;
    margin-left: 30px;
}
.px-cfg-buttons .grp:first-child {
    margin-left: 0px;
}
.px-cfg-buttons .grp.edit:first-child {
	margin-left: 30px;
}
.px-cfg-buttons .buttons, .px-cfg-buttons .filter {
	float: left;
}
.chrono-timeline .sl-ent .px-cfg-buttons .filter a {
}
.chrono-timeline .sl-ent .px-cfg-buttons .filter a i {
	font-size: 20px;
	margin-left: 5px;
	position: absolute;
	margin-top: -3px;
}
.chrono-timeline .sl-ent .px-cfg-buttons .buttons a.button,
.chrono-timeline .sl-ent .px-cfg-buttons .filter a.button {
	margin-bottom:7px;
}
.chrono-timeline .sl-ent .px-cfg-buttons .details1,
.chrono-timeline .sl-ent .px-cfg-buttons .details2,
.chrono-timeline .sl-ent .px-cfg-buttons .details3 {
	display:block;
	clear:both;
}
.chrono-timeline .sl-ent .px-cfg-buttons .details1 a.button,
.chrono-timeline .sl-ent .px-cfg-buttons .details2 a.button,
.chrono-timeline .sl-ent .px-cfg-buttons .details3 a.button {
	margin-bottom:5px;
}

.fadeout {
	position:relative;
	max-height:200px;
	overflow:hidden;
}
.fadeout:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 5em;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) 90%);
}
.fadeout:before {
	content: "";
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    top: 0px;
    opacity: 0.5;
    cursor: pointer;
}

.comment.fadeout:after {
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), #fffdd8 90%);
}