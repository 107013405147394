table > thead > tr > th:nth-child(1) {
    /* styles */
    width: 5rem!important;
}
table > thead > tr > th {
    /* styles */
    width: 8rem!important;
}
.MuiPaper-root{
    z-index: 0;
}
.ticket-link{
    background-color: #787D96;
}
.ticket-link:hover {
    background-color:#787d96ed;
}
.MuiTableCell-head > span {
	min-width: 120px;
}
.MuiTableCell-body {
	overflow: hidden;
    text-overflow: ellipsis;
}