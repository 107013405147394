.dateselector {
    display: flex;
    flex-direction: column;
}
.dateselector .from {
    padding-bottom: 5px;
}
.dateselector .from, .dateselector .to {
    flex-flow: row;
    display: flex;
    align-items: center;
}
.dateselector span {
    width: 50px;
}

