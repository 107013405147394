/*
 PxPortal Styles
*/
.App{
    background-color: #f6f9fa;
}
.topbar{
    height: 80px;
}
.topbar-right{
    display: flex;
    gap: 20px;
}
.topbar-right a {
    display: flex;
}
.page-wrapper{
    margin-top: 20px;
    /*box-shadow: 1px 0px 20px rgb(0 0 0 / 8%); */
    background-color: inherit;
}
.left-sidebar{
    padding-top: 80px;
    width: 200px;
}
.sidebar-link {
    color: black!important;
}
.topbartoggler {
    color: black!important;
}
.px-logo{
    padding-top: 15px;
    /*height: 140px; */
}
.px-menue-item-icon {
    padding-right: 20px;
}
.px-nav-toggler{
    color: black!important;
}
.px-menue-title{
    color: black!important;
}
.px-menue-item{
    color: black!important;
}
.px-col{
    padding-left:0;
}
.card {
    box-shadow: 1px 0px 20px rgb(0 0 0 / 8%);
}
.btn {
    border-radius:5px;
}
.btn-info{
    background-color: #252b4a;
    border-color: #252b4a;
}
.btn-info:hover{
    background-color: #2C4361!important;
    border-color: #2C4361!important;
}
.btn-info:active{
    background-color: #2C4361;
    border-color: #2C4361;
}
.btn-info:focus{
    background-color: #2C4361!important;
    border-color: #2C4361!important;
}

.btn-green{
    background-color: #47b900;
    border-color: #47b900;
    color:white;
}
.btn-green:hover{
    background-color: #51d200!important;
    border-color: #51d200!important;
}
.btn-green:active{
    background-color: #51d200;
    border-color: #51d200;
}
.btn-green:focus{
    background-color: #51d200!important;
    border-color: #51d200!important;
}

.btn-neutral {
    background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
}

.btn-neutral:hover {
    filter: brightness(93%);
}

.btn-passive{
    background-color: #4b4c52;
    border-color: #4b4c52;
    color: white;
}
.btn-passive:hover{
    filter: brightness(93%);
}
.btn-passive:active{
    background-color: #4b4c52;
    border-color: #4b4c52;
}
.btn-passive:focus{
    background-color: #4b4c52!important;
    border-color: #4b4c52!important;
}

#ticket-notifier { position:relative; }
#ticket-notifier .count {
	position: absolute;
	margin-top: 12px;
	right: -6px;
	font-weight: bold;
	background-color: #1670ca;
	border-radius: 1em;
	color: white;
	min-width: 1em;
	padding: .3em .4em .4em;
	min-height: 1em;
	line-height: 0.8em;
	display: inline-block;
	font-size: 0.8em;
	display:none;
}
#ticket-notifier .count.unread { display:block; }

.row.dashboard .card {
	max-height: 150px;
}
.total_number > div:nth-child(2) { padding:1em; }
.total_number h6 { display: flex; align-items: center; }


.comment-row h5 {
	padding-top:0.5rem;
}

.overlay-mask {
    display: none;
    height: 100;
    opacity: 0.5;
    filter: alpha(opacity=10);
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 200px;
    position: fixed;
    -webkit-transition: 0.5s ease-in;
    transition: 3s ease-in;
}

#main-wrapper.show-menu-mask .overlay-mask{
    display: block; }

.noselect {
        -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
      }
.MuiTableCell-body {
    text-overflow: unset!important;
}
 .MuiPaper-root .card-title {
    position: relative;
    font-weight: 600;
    margin-bottom: 10px;
	color: rgb(62, 85, 105);
}
#main-wrapper .MuiToolbar-gutters {
	padding-left: 16px;
}
#main-wrapper .MTableToolbar-spacer-7 {
	flex: 1 1 1%;
}
#main-wrapper .overview-invoice table .MuiTableBody-root tr > td:nth-child(1),
#main-wrapper .overview-invoice table > thead > tr > th:nth-child(1) {
	position:relative;
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	padding-right:0px;
}
#main-wrapper .overview-invoice table .MuiTableBody-root tr > td:nth-child(2),
#main-wrapper .overview-invoice table > thead > tr > th:nth-child(2) {
	position:relative;
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	padding-right:0px;
}
#main-wrapper .overview-invoice table > thead > tr > th:nth-child(3) {
	position:relative;
	width: 300px;
	max-width: 300px;
	min-width: 300px;
	padding-right:0px;
}
#main-wrapper .overview-invoice table > thead > tr > th:nth-child(4),
#main-wrapper .overview-invoice table > thead > tr > th:nth-child(5),
#main-wrapper .overview-invoice table > thead > tr > th:nth-child(6) {
	text-align: right;
	justify-content: right;
	width: 60px;
	max-width: 60px;
	min-width: 60px;
}
#main-wrapper .overview-invoice table > thead > tr > th:nth-child(4) span,
#main-wrapper .overview-invoice table > thead > tr > th:nth-child(5) span,
#main-wrapper .overview-invoice table > thead > tr > th:nth-child(6) span {
	text-align: right;
	justify-content: right;
	margin-right:-10px;
}
#main-wrapper .overview-invoice table > thead > tr > th:nth-child(7) {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
}

.px-chat .messages > div {
	border-left:0;
}

.px-dash-icon:hover {
    filter: brightness(88%);
    cursor: pointer;
}
