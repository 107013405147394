body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.centered-el {
	width: 100%;
    height: 100%;
    position: absolute;
}
.centered-el {
	text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: flex;
    font-family: Segoe UI,Helvetica Neue,Helvetica,Lucida Grande,Arial,Ubuntu,Cantarell,Fira Sans,sans-serif;
    margin: 0;
    margin-top: -1.25rem;
}
.centered-el h1 {
	font-size: 36px;
    font-weight: 300;
    color: var(--primary-title);
}
.centered-el h3 {
	font-weight: 300;
    color: var(--primary-title);
}

/* TINYMCE */
.chrono-timeline .tox-tinymce {
  border:none;
  border-radius:0px;
  box-sizing: content-box;
}
.chrono-timeline .tox:not(.tox-tinymce-inline) .tox-editor-header {
  border-bottom: 1px solid #ccc;
  box-shadow:none;
  padding:0;
}
.chrono-timeline .tox .tox-statusbar {
  border-top: 1px solid #ccc;
}
#timeline .menu {
  display: flex;
}
#timeline .menu .mail {
  text-align: center;
    align-items:flex-end;
    justify-content: end;
    width: 100%;
    display: flex;
}
#timeline .chrono-timeline .sl-ent .bubble {
	background-color:white;
	border-color: #e6e6e6;
 }
#timeline .chrono-timeline .content.message.info .bubble {
  color:#4d7851;
  background-color:#e9f6e7;
}
#timeline .chrono-timeline .bubble .arrow {
  fill:white;
}
#timeline .chrono-timeline .content.message.info .bubble .arrow {
  fill:#e9f6e7;
}